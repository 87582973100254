import React, { useContext, useEffect, useState } from 'react';
import InnerBanner from '../components/InnerBanner';
import * as API from '../api/index';
import { useLocation, useNavigate } from 'react-router';
import { IMG, NOIMG } from '../api/constant';
import { Link } from 'react-router-dom';
import { MESSAGE, TOKEN_CODE } from '../schemas/Validation';
import AudioTrack from '../components/AudioTrack';
import CommonCata from '../components/CommonCata';
import CataDetails from './CataDetails';
import MessagePlacePage from './MessagePlacePage';
import Loader from '../components/Loader';

const convertToMMSS = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.round(seconds % 60);
  return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
};

const MusicTemplete = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [musicIndex, setMusicIndex] = useState('');
  const [songData, setSongData] = useState([]);
  const [isPlaying, setIsPlaying] = useState(false);
  const [trackIndex, setTrackIndex] = useState(0);
  const [currentTrack, setCurrentTrack] = useState(songData[trackIndex]);

  const [cataGoriData, setCataGoriData] = useState([]);

  const musiaChoose = (index, songid) => {
    setIsPlaying(true);
    setMusicIndex(songid);
    setCurrentTrack(songData[index]);
    setTrackIndex(index);
  };

  const getVatagoriy_details = async () => {
    const header = localStorage.getItem('_tokenCode');
    const musicData = JSON.parse(localStorage.getItem('__musicData'));

    if (!musicData || !musicData.song_id) {
      navigate('/song-list');
      return;
    }

    setLoading(true);

    try {
      const response = await API.songWiseTempleteList(musicData.song_id, header);
      localStorage.setItem('_cataGorid', response.data.data.category_id);
      setCataGoriData(response.data.data);
      setCurrentTrack(response.data.data.music[trackIndex]);
      setSongData(response.data.data.music);
    } catch (error) {
      console.error('Error fetching category details:', error);
    } finally {
      setLoading(false);
    }
  };

  const add_music_user = (typeTemp, amount, messlength, item = {}) => {
    try {
      const songObj = {
        temtype: typeTemp,
        amount: amount,
        duration: messlength,
        intro_outro_length: item?.intro_outro_length || 0,
      };
      localStorage.setItem('__template', JSON.stringify(songObj));
    } catch (error) {
      console.error('Error storing music user template:', error);
    }
  };

  const handleAmountClick = (id) => {
    const selectedSong = songData.find((song) => song.id === id);
    if (selectedSong) {
      localStorage.setItem('__selectedSongName', JSON.stringify(selectedSong.name));
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getVatagoriy_details();
  }, []);

  return (
    <>
      <div className='ms_genres_wrapper'>
        <div className='row justify-content-center'>
          <div className='col-md-9'>
            <div className='album_single_data'>
              <div className='album_single_img'>
                <img src={cataGoriData.image === undefined ? NOIMG : IMG + cataGoriData.image} alt='' className='img-fluid' />
              </div>
              <div className='album_single_text'>
                <h2>{cataGoriData.name}</h2>
                <div className='about_artist'>{cataGoriData.description}</div>
              </div>
            </div>

            <div className='album_inner_list'>
              <div className='album_list_wrapper mobileList'>
                <table className='album_table w-100'>
                  <thead>
                    <tr>
                      <th>Select</th>
                      <th>Sample Type</th>
                      <th>Duration Song</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td colSpan='4' className='text-center'>
                          <Loader customHeight={false} />
                        </td>
                      </tr>
                    ) : songData.length > 0 ? (
                      songData.map((item, index) => (
                        <tr key={item.id} className={musicIndex === item.id ? 'songActive' : ''}>
                          <td onClick={() => musiaChoose(index, item.id)}>
                            <Link
                              to='/message-placement'
                              className='cart_btn'
                              state={{ tamId: item.id }}
                              onClick={() => {
                                add_music_user(item.template_type_status, item.amount, item.messlength, item);
                                handleAmountClick(item.id);
                              }}
                            >
                              <i className='fa fa-plus' aria-hidden='true'></i>
                            </Link>
                          </td>
                          <td onClick={() => musiaChoose(index, item.id)}>
                            <Link to='javascript:void(0)'>
                              {isPlaying && musicIndex === item.id ? (
                                <img className='playIcon' src='https://m.media-amazon.com/images/G/01/digital/music/player/web/EQ_accent.gif' alt='Play' />
                              ) : (
                                <span className='play_hover'></span>
                              )}
                            </Link>
                            <Link to='javascript:void(0)'>{item.name}</Link>
                          </td>
                          <td onClick={() => musiaChoose(index, item.id)}>
                            <Link to='javascript:void(0)'>{convertToMMSS(item.duration_seconds)}</Link>
                          </td>
                          <td onClick={() => musiaChoose(index, item.id)}>{item.amount === '' ? 'Free' : <Link to='javascript:void(0)'>$ {item.amount}</Link>}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan='4' className='text-center' style={{ padding: '20px 0', fontSize: '25px' }}>
                          Data Not Found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div className='ms_view_more padder_bottom20'>
          <Link className='ms_btn' to='/song-list'>
            Back
          </Link>
        </div>
      </div>

      {songData.length === 0 ? (
        ''
      ) : (
        <>
          <AudioTrack
            musiaChoose={musiaChoose}
            musicIndex={musicIndex}
            setMusicIndex={setMusicIndex}
            cataName={cataGoriData.category_name}
            tracks={songData}
            setTrackIndex={setTrackIndex}
            trackIndex={trackIndex}
            currentTrack={currentTrack}
            setCurrentTrack={setCurrentTrack}
            setIsPlaying={setIsPlaying}
            isPlaying={isPlaying}
          />
        </>
      )}
    </>
  );
};

export default MusicTemplete;
